<!--
*  TTTech nerve-management-system
*  Copyright(c) 2024. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <login logo="/img/login.svg" image="/img/login-background.jpg">
    <template #login-actions>
      <div id="iiotActivateMFA">
        <v-form id="iiotActivateMFALeftPart">
          <div class="title mb-10">
            <h3>{{ $t('login.activateMFA.title') }}</h3>
          </div>

          <div v-show="urlReceived" id="iiotQRContainer">
            <canvas id="iiotQRCanvas"></canvas>
          </div>

          <v-layout v-if="urlReceived" class="mt-1 justify-center">
            <a
              id="iiotUserManualLink"
              href="https://docs.nerve.cloud/user_guide/management_system/mfa/"
              target="_blank"
            >
              {{ $t('login.activateMFA.userManualLink') }}
            </a>
          </v-layout>

          <v-layout v-if="urlReceived" class="mt-12 justify-center">
            <nerve-button
              id="iiotActivateMFAConfirmButton"
              data-cy="iiotActivateMFAConfirmButton"
              :disabled="alreadyClicked"
              :text="$t('login.activateMFA.confirmBtn')"
              type-of-btn="action"
              size="normal"
              @click-event="setMFASecret"
            />
          </v-layout>
        </v-form>
      </div>
    </template>
  </login>
</template>

<script>
import { Login, NerveButton } from 'nerve-ui-components';
import QRCode from 'qrcode';

export default {
  name: 'MultiFactorAuthenticationQRCode',
  components: { NerveButton, Login },
  data: () => ({
    token: '',
    alreadyClicked: false,
    urlReceived: false,
  }),
  async mounted() {
    if (window.location.pathname.includes('/mfa/activate')) {
      const [, activationToken] = window.location.pathname.split('/mfa/activate/');
      this.token = activationToken;
    } else if (window.location.pathname.includes('/mfa/reset')) {
      const [, resetToken] = window.location.pathname.split('/mfa/reset/');
      this.token = resetToken;
    }

    try {
      await this.$store.dispatch('multi-factor-authentication/generateMFASecret', {
        token: this.token,
      });

      const mfaUrl = await this.$store.getters['multi-factor-authentication/mfaUrl'];
      if (mfaUrl) {
        await QRCode.toCanvas(document.getElementById('iiotQRCanvas'), mfaUrl, { errorCorrectionLevel: 'L' }, () => {});
        this.urlReceived = true;
      }
    } catch (e) {
      await this.showErrorToast(e);
      setTimeout(() => {
        this.$router.push({ name: 'Login' });
      }, 1200);
    }
  },
  methods: {
    async setMFASecret() {
      this.alreadyClicked = true;
      const mfaSecret = await this.$store.getters['multi-factor-authentication/mfaSecret'];

      try {
        if (window.location.pathname.includes('/mfa/activate')) {
          await this.$store.dispatch('multi-factor-authentication/activateMFA', {
            activationToken: this.token,
            secret: mfaSecret,
          });
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: this.$t('login.activateMFA.mfaActivated'),
            color: 'success',
            showClose: true,
          });
        } else if (window.location.pathname.includes('/mfa/reset')) {
          await this.$store.dispatch('multi-factor-authentication/resetMFA', {
            resetToken: this.token,
            secret: mfaSecret,
          });
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: this.$t('login.activateMFA.mfaReset'),
            color: 'success',
            showClose: true,
          });
        }

        setTimeout(() => {
          this.$router.push({ name: 'Login' });
        }, 1200);
      } catch (e) {
        this.alreadyClicked = false;
        await this.showErrorToast(e);
      } finally {
        setTimeout(() => {
          this.$router.push({ name: 'Login' });
        }, 1200);
      }
    },
    async showErrorToast(error) {
      this.$log.debug(error);
      const { errorCode } = error.response.data[0];
      await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: this.$t(`errorMessages.${errorCode}`),
        color: 'red',
        showClose: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#iiotActivateMFALeftPart {
  padding: 50px;
  .title {
    > h3 {
      text-align: center;
      font-size: 23px;
    }
  }
  #iiotQRContainer {
    margin: 0 auto;
    display: block;
    aspect-ratio: 1 / 1;
    width: 100%;
    max-width: 212px;
    #iiotQRCanvas {
      margin: 0 auto;
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  #iiotUserManualLink {
    text-align: center;
    text-decoration: none;
  }
}
</style>
