import { render, staticRenderFns } from "./MultiFactorAuthenticationQRCode.vue?vue&type=template&id=80977672&scoped=true"
import script from "./MultiFactorAuthenticationQRCode.vue?vue&type=script&lang=js"
export * from "./MultiFactorAuthenticationQRCode.vue?vue&type=script&lang=js"
import style0 from "./MultiFactorAuthenticationQRCode.vue?vue&type=style&index=0&id=80977672&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80977672",
  null
  
)

export default component.exports